// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import Label from "../../../components/Label";
import SvgIconStyle from "../../../components/SvgIconStyle";

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: getIcon("ic_user"),
  ecommerce: getIcon("ic_ecommerce"),
  dashboard: getIcon("ic_dashboard"),
  master: getIcon("ic_master"),
  parent: getIcon("ic_parent"),
  report: getIcon("ic_report"),
  ticket: getIcon("ic_bug"),
  orderStatus: getIcon("ic_orderStatus"),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "general",
    items: [
      {
        title: "Dashboard",
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard,
      },
      {
        title: "Orders",
        path: PATH_DASHBOARD.general.schoolOrder,
        icon: ICONS.ecommerce,
      },
      // {
      //   title: "Global Order Search",
      //   path: PATH_DASHBOARD.general.globalSearchOrder,
      //   icon: ICONS.ecommerce,
      // },
      // { title: 'Create Order', path: PATH_DASHBOARD.general.adminOrder, icon: ICONS.ecommerce },
      // { title: 'Check Order Status', path: PATH_DASHBOARD.general.orderStatus, icon: ICONS.orderStatus },
      // { title: 'Missing Items', path: PATH_DASHBOARD.general.tickets, icon: ICONS.ticket },
      {
        title: "Enquiry List",
        path: PATH_DASHBOARD.general.raisedTicket,
        icon: ICONS.orderStatus,
      },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: "Master",
    items: [
      // USER
      {
        title: "Master",
        path: PATH_DASHBOARD.master.root,
        icon: ICONS.master,

        children: [
          { title: "Product", path: PATH_DASHBOARD.master.product },
          { title: "Category", path: PATH_DASHBOARD.master.category },
          { title: "Description", path: PATH_DASHBOARD.master.description },
          { title: "Variations", path: PATH_DASHBOARD.master.variations },
          { title: "Testimonial", path: PATH_DASHBOARD.master.testimonial },
          { title: "PromoCode", path: PATH_DASHBOARD.master.promoCode },
          { title: "Pincode", path: PATH_DASHBOARD.master.pincode },
        ],
      },
    ],
  },

  // {
  //   subheader: "User",

  //   items: [
  //   //  USER
  //     {
  //       title: "Parent",
  //       path: PATH_DASHBOARD.general.parentList,
  //       icon: ICONS.parent,
  //     },
  //     {
  //       title: "Customer",
  //       path: PATH_DASHBOARD.general.studenstList,
  //       icon: ICONS.user,
  //     },
  //   ],
  // },

  // {
  //   subheader: "Reports",
  //   items: [
  //     // USER
  //     {
  //       title: "Order Reports",
  //       path: PATH_DASHBOARD.reports.root,
  //       icon: ICONS.report,

  //       children: [
  //         {
  //           title: "New Order",
  //           path: PATH_DASHBOARD.reports.newReport,
  //           icon: ICONS.user,
  //         },
  //         {
  //           title: "Pending Order",
  //           path: PATH_DASHBOARD.reports.pendingReport,
  //           icon: ICONS.user,
  //         },
  //         {
  //           title: "New & Pending Order",
  //           path: PATH_DASHBOARD.reports.newAndPending,
  //           icon: ICONS.user,
  //         },

  //         {
  //           title: "Product Order",
  //           path: PATH_DASHBOARD.reports.dateWiseReport,
  //           icon: ICONS.user,
  //         },

  //       ],
  //     },
  //   ],
  // },

  //     // E-COMMERCE
  //     {
  //       title: 'e-commerce',
  //       path: PATH_DASHBOARD.eCommerce.root,
  //       icon: ICONS.cart,
  //       children: [
  //         { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
  //         { title: 'product', path: PATH_DASHBOARD.eCommerce.demoView },
  //         { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
  //         { title: 'create', path: PATH_DASHBOARD.eCommerce.new },
  //         { title: 'edit', path: PATH_DASHBOARD.eCommerce.demoEdit },
  //         { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
  //       ],
  //     },

  //     // INVOICE
  //     {
  //       title: 'invoice',
  //       path: PATH_DASHBOARD.invoice.root,
  //       icon: ICONS.invoice,
  //       children: [
  //         { title: 'list', path: PATH_DASHBOARD.invoice.list },
  //         { title: 'details', path: PATH_DASHBOARD.invoice.demoView },
  //         { title: 'create', path: PATH_DASHBOARD.invoice.new },
  //         { title: 'edit', path: PATH_DASHBOARD.invoice.demoEdit },
  //       ],
  //     },

  //     // BLOG
  //     {
  //       title: 'blog',
  //       path: PATH_DASHBOARD.blog.root,
  //       icon: ICONS.blog,
  //       children: [
  //         { title: 'posts', path: PATH_DASHBOARD.blog.posts },
  //         { title: 'post', path: PATH_DASHBOARD.blog.demoView },
  //         { title: 'create', path: PATH_DASHBOARD.blog.new },
  //       ],
  //     },
  //   ],
  // },

  // APP
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'app',
  //   items: [
  //     {
  //       title: 'mail',
  //       path: PATH_DASHBOARD.mail.root,
  //       icon: ICONS.mail,
  //       info: (
  //         <Label variant="outlined" color="error">
  //           +32
  //         </Label>
  //       ),
  //     },
  //     { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
  //     { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
  //     { title: 'kanban', path: PATH_DASHBOARD.kanban, icon: ICONS.kanban },
  //   ],
  // },
];

export default navConfig;
