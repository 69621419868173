import { useState } from "react";
import {
  Container,
  List,
  ListItemText,
  Grid,
  ListItemAvatar,
  Avatar,
  Typography,
  Pagination,
  Paper,
  Stack,
  ListItemButton,
  LinearProgress,
  TextField,
  Divider,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Label from "src/components/Label";
import axios from "axios";
import Iconify from "src/components/Iconify";
import Page from "src/components/Page";
import useSettings from "src/hooks/useSettings";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import { PATH_DASHBOARD } from "src/routes/paths";
import { useSnackbar } from "notistack";
import { contactListupdate } from "../api";
import { fDateTimeNew } from "src/utils/formatTime";
import { LoadingButton } from "@mui/lab";
import { HOST_API } from "src/config";

export default function ContactUS() {
  const { themeStretch } = useSettings();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [globalFilter, setGlobalFilter] = useState("");
  const [value, setValue] = useState("");
  const [remarks, setRemarks] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: 10,
  });

  const { data, isLoading } = useQuery({
    queryKey: [
      "raisedTicket",
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
    ],
    queryFn: async () => {
      const response = await axios.post(`${HOST_API}contactUs/admin/get`, {
        id: 0,
      });
      console.log("API Response:", response.data);

      const responseData = response.data.data || [];

      return responseData.map((item) => ({
        id: item.id,
        userId: item.user_id,
        name: item.name,
        email: item.email_id,
        phone: item.phone,
        location: item.location,
        interestArea: item.interest_area,
        message: item.message,
        createdDate: item.created_date,
        modifiedDate: item.modified_date,
        status: item.status,
      }));
    },
  });

  const mutation = useMutation((req) => contactListupdate({ req }), {
    onSuccess: ({ data }) => {
      enqueueSnackbar("Status Updated", {
        variant: "success",
      });
      queryClient.invalidateQueries(["raisedTicket"]);
      setSelectedRow((ps) => ({
        ...ps,
        status: +data[0]?.status,
        response: data[0]?.response,
      }));
      setSelectedStatus("");
      setRemarks("");
    },
    onError: (error) => {
      enqueueSnackbar(error?.message || "Failed to Update", {
        variant: "error",
      });
    },
  });

  // const handleExportCsv = async () => {
  //   try {
  //     const config = {
  //       method: "GET",
  //       url: "tickets/admin/Ticketscsv",
  //       responseType: "blob",
  //     };
  //     const { data } = await axiosInstance(config);
  //     const outputFilename = `raised-ticket-${new Date()}.csv`;
  //     const url = URL.createObjectURL(data);
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.setAttribute("download", outputFilename);
  //     document.body.appendChild(link);
  //     link.click();
  //     link.remove();
  //   } catch (error) {
  //     enqueueSnackbar("Something Went Wrong", { variant: "error" });
  //   }
  // };

  const handlePageChange = (event, newPage) => {
    event.preventDefault();
    setPagination((ps) => ({ ...ps, pageIndex: newPage }));
  };

  const handleUpdateStatus = () => {
    if (!selectedStatus) {
      enqueueSnackbar("Please Select Status", {
        variant: "error",
      });
      return;
    }
    const req = {
      id: selectedRow.id,
      response: remarks,
      status: selectedStatus,
    };
    mutation.mutate(req);
  };
  const handleFilter = () => {
    setGlobalFilter(value);
  };

  return (
    <>
      <Page title="Enquiry List">
        <Container maxWidth={themeStretch ? false : "xl"}>
          <HeaderBreadcrumbs
            heading=" Enquiry List"
            links={[
              { name: "Dashboard", href: PATH_DASHBOARD.root },
              { name: " Enquiry List" },
            ]}
          />
          {/* <Stack direction={"row"} mb={1}>
            <LoadingButton
              onClick={handleExportCsv}
              variant="contained"
              color="primary"
            >
              Export CSV
            </LoadingButton>
          </Stack> */}
          <Grid container spacing={1}>
            <Grid item xs={5}>
              <Paper
                elevation={4}
                sx={{ py: 2, maxHeight: "800px", height: "100%" }}
              >
                <Typography sx={{ px: 2 }} variant="h5">
                  Enquiry List
                </Typography>
                {isLoading && <LinearProgress />}
                {/* {data && (
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    gap={2}
                    pl={2}
                    pr={2}
                    mt={2}
                  >
                    <TextField
                      placeholder="Search by... name, ticket number"
                      value={value}
                      onChange={(e) => {
                        setValue(e.target.value);
                      }}
                      fullWidth
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              size="small"
                              onClick={() => {
                                setValue("");
                                setGlobalFilter("");
                              }}
                            >
                              <Iconify icon={"material-symbols:close"} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <LoadingButton
                      variant="contained"
                      color="primary"
                      onClick={handleFilter}
                      loading={isLoading}
                    >
                      Search
                    </LoadingButton>
                  </Stack>
                )} */}
                {!isLoading && (!data || data.length === 0) && (
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    sx={{ textAlign: "center", mt: 4 }}
                  >
                    No enquiries found.
                  </Typography>
                )}
                {data && data.length > 0 && (
                  <List
                    sx={{
                      width: "100%",
                      background: (theme) => theme.palette.background.paper,
                    }}
                  >
                    {data?.map((el, index) => (
                      <ListItemButton
                        key={index}
                        onClick={() => {
                          setSelectedRow(el);
                        }}
                        selected={el?.id === selectedRow?.id}
                      >
                        <ListItemAvatar>
                          <Avatar>
                            <Iconify icon={"mdi:user"} />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography variant="subtitle2">
                              {el.name}{" "}
                              <Typography
                                component={"span"}
                                color={"grey.600"}
                                variant="subtitle2"
                              >
                                ({el?.location}){" "}
                                {`${fDateTimeNew(el?.createdDate) || ""}`}
                              </Typography>
                            </Typography>
                          }
                          // secondary={
                          //   <Typography variant="body2">
                          //     {`${
                          //       fDateTimeNew(el?.createdDate) || ""
                          //     }`}{" "}
                          //   </Typography>
                          // }
                        />
                        {/* <Label
                        size="small"
                        variant="ghost"
                        color={
                          el?.status === 1
                            ? "error"
                            : el?.status === 2
                            ? "primary"
                            : "success"
                        }
                      >
                        {el?.status}
                      </Label> */}
                      </ListItemButton>
                    ))}
                  </List>
                )}
                {data && data.length > 0 && (
                  <Stack sx={{ justifyContent: "end", width: "100%", mt: 2 }}>
                    <Pagination
                      count={Math.ceil(data?.length / pagination.pageSize)}
                      color="primary"
                      page={pagination.pageIndex}
                      onChange={handlePageChange}
                      sx={{ justifyContent: "end" }}
                      disabled={!!data?.length < 0 || isLoading}
                    />
                  </Stack>
                )}
              </Paper>
            </Grid>
            <Grid item xs={7}>
              {selectedRow && (
                <Paper elevation={4} sx={{ p: 2 }}>
                  <Typography variant="h5">
                    {selectedRow?.name}&nbsp;
                    <Typography component={"span"} variant="subtitle2">
                      (Location: <b>{selectedRow?.location}</b>)
                    </Typography>
                  </Typography>
                  <Typography variant="body1" color="grey.700">
                    Email : <b>{selectedRow?.email}</b>
                  </Typography>
                  <Typography variant="body1" color="grey.700">
                    Phone : <b>{selectedRow?.phone}</b>
                  </Typography>
                  <Typography variant="body1" color="grey.700">
                    Interest Area : <b>{selectedRow?.interestArea}</b>
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    Message : <b>{selectedRow?.message}</b>
                  </Typography>
                  {/* <Divider sx={{ my: 2 }} /> */}
                  {/* {selectedRow?.response && (
                    <Stack direction={"row"} justifyContent={"flex-end"}>
                      <div>
                        <Typography sx={{ textAlign: "right" }}>
                          {selectedRow?.response || ""}
                        </Typography>
                        <Typography variant="caption">
                          Admin Remarks {selectedRow?.resolveDate || ""}
                        </Typography>
                      </div>
                    </Stack>
                  )} */}
                  {/* {selectedRow.status !== 3 ? (
                    <>
                      <TextField
                        select
                        SelectProps={{ native: true }}
                        fullWidth
                        size="small"
                        sx={{ mb: 1 }}
                        value={selectedStatus}
                        onChange={(e) => {
                          setSelectedStatus(e.target.value);
                        }}
                      >
                        <option value={""}>Select Status</option>
                        <option value={"2"}>Seen</option>
                      </TextField>
                      <TextField
                        fullWidth
                        size="small"
                        value={remarks}
                        onChange={(e) => setRemarks(e.target.value)}
                        placeholder="Remarks..."
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton edge="start">
                                <Iconify icon={"ic:outline-note"} />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Stack
                        direction={"row"}
                        justifyContent={"flex-end"}
                        sx={{ mt: 2 }}
                      >
                        <LoadingButton
                          variant="contained"
                          color="info"
                          onClick={handleUpdateStatus}
                          loading={mutation.isLoading}
                        >
                          Submit
                        </LoadingButton>
                      </Stack>
                    </>
                  ) : null} */}
                </Paper>
              )}
            </Grid>
          </Grid>
        </Container>
      </Page>
    </>
  );
}
